<template>
  <div class="image-text-nav">
    <div class="content">
      <ModTit
        :title="
          module.menuJsonObj
            ? module.menuJsonObj[langNamesEnum[languageActive]]
            : module.title
        "
      />
      <div
        class="card"
        :style="{
          gridTemplateColumns:
            'repeat(' + dataSource.configuration.rowCol + ', 1fr)',
        }"
      >
        <div
          :class="['item', dataSource.configuration.displayType]"
          v-for="(item, index) in dataSource.dataList.BannerList"
          :key="index"
          @click="jump(item)"
          :style="[
            (item.jumpType == 1 && item.jumpUrl == '') ||
            (item.jumpType == 2 && item.resourceId == 0)
              ? ''
              : 'cursor: pointer;',
          ]"
        >
          <div
            class="img"
            :style="{ backgroundImage: 'url(' + item.coverPath + ')' }"
          ></div>
          <div
            class="text"
            v-if="dataSource.configuration.displayType != 'only'"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModTit from "@/components/layout/ModTit.vue";
export default {
  name: "imageTextNav",
  components: {
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(_, { emit }) {
    const jump = (data) => {
      emit("jump", data);
    };
    return {
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.image-text-nav {
  .content {
    .mixinWrap();
    .card {
      display: grid;
      gap: 20px;
      .item {
        &.top {
          .mixinFlex();
          flex-direction: column;
          .img {
            width: 100%;
            height: 57px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          .text {
            width: 100%;
            padding: 0 4px;
            margin: 5px 0;
            font-size: 16px;
            text-align: center;
            word-break: break-all;
            .mixinEllipsis(52px; 2);
          }
        }
        &.left {
          .mixinFlex(flex-start; center);
          .img {
            width: 50%;
            height: 29px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          .text {
            width: 50%;
            padding: 0 4px;
            margin: 5px 0;
            font-size: 16px;
            word-break: break-all;
            .mixinEllipsis(29px);
          }
        }
        &.only {
          .img {
            width: 100%;
            height: 57px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }
}
</style>
